<template>
  <div>
    <img src='../../assets/imgs/pic_noState.png'>
    <div class="text">你还没有发布动态哦~</div>
  </div>
</template>

<script>
  export default {
    name: "PersonalStateMain",
    data() {
      return {

      }
    }
  }
</script>

<style scoped>
  img {
    width: 100%;
  }

  .text {
    text-align: center;
    color: #BD9292;
    padding-bottom: 20px;
  }
</style>