<template>
  <ResetMain></ResetMain>
</template>

<script>
import ResetMain from '/src/components/Revamp/ResetMain'
export default {
  name:"ResetPsw",
  data(){
    return{
    }
  },
  methods:{
    
  },
  components:{
    ResetMain
  }

}
</script>

<style scoped>

</style>