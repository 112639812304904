<template>
  <MyIssueMain></MyIssueMain>
</template>

<script>
  import MyIssueMain from '@/components/MyIssue/MyIssueMain.vue'
  export default {
    name: "MyIssue",
    data() {
      return {

      }
    },
    components: {
      MyIssueMain
    }
  }
</script>

<style scoped>

</style>