<template>
    <div class="wrapper">
        <publish-main></publish-main>
    </div>
</template>


<script>
  import PublishMain from '@/components/Publish/PublishMain'
  export default {
    name: 'Publish',
    components:{
        PublishMain
    },
    props:{},
    data(){
      return {
          
      }
    },
    created(){},
    mounted(){},
    activated(){},
    updated(){},
    methods:{},
    computed:{},
    watch:{},
  }
</script>
<style scoped lang='scss'>
</style>