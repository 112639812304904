<template>

</template>

<script>
export default {
  name: "HomeTop",
  data () {
    return {
      input: ''
    }
  }
}
</script>

<style scoped>

</style>