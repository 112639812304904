<template>
    <Goods></Goods>
</template>

<script>
import Goods from '../components/MyGoods/Goods.vue'
export default {
    name:'MyGoods',
    components:{Goods}
}
</script>

<style>

</style>