<template>
  <div>
    <LoginMain></LoginMain>
  </div>
</template>

<script>
  import LoginMain from "@/components/Login/LoginMain.vue"
export default {
  data(){
    return{

    }
  },
  methods:{

  },
  components:{
    LoginMain
  }
}
</script>

<style>

</style>