<template>
    <div>
        <!-- <PersonalDetail></PersonalDetail> -->
        <router-view></router-view>
    </div>
</template>

<script>
// import PersonalDetail from '../components/Message/PersonalDetail.vue'
export default {
    name:'Message',
    // components:{PersonalDetail}
}
</script>

<style>

</style>